import React from 'react'
import cx from 'classnames'

import { useInView } from 'react-intersection-observer'
import { Marqy } from 'marqy'
import 'marqy/styles.css'

import { Image } from 'src/components/image'

export interface AboutAnythingProps {
  data: {
    color?: string
    title?: string
    subDescription?: string
    spices: any[]
    image: {
      imageId: string
      altText?: string
    }
    betterFoodCTA: string
    subText?: string
  }
}

export const AboutAnything = ({ data }: AboutAnythingProps) => {
  const {
    color,
    title,
    spices,
    subText,
    subDescription,
    image,
    betterFoodCTA
  } = data

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
  })

  return (
    <div className={cx('x pr of--hidden pt4--800 pb4--800 pt8--1000 bt color--blue', `bg--${color}`)}>
      <div className='container--xl tc pt4 pt3--800 pb3--800 outer mxa py2 p1 al p x'>
        <h2 className='h1 s80'>{title}</h2>
      </div>
      <div className='p2 container--1200 mxa tc'>
        <div className='row'>
          <div className='col c2--800' />
          <div className='col c14 c8--800'>
            <p className='s24 s36--1000'>{subText}</p>
          </div>
          <div className='col c2--800' />
        </div>
      </div>
      <div className='spice__wrapper df jcc aic pr pt4--800 pb4--800'>
        <Marqy speed='0.3'>
          <div>
            <img className='x spice__wrapper-img' src='/images/pod-long.png' />
          </div>
        </Marqy>
      </div>

      <div className='p2 container--1200 mxa tc'>
        <div className='row'>
          <div className='col c2--800' />
          <div className='col c14 c9--800'>
            <p className='s24 s36--1000'>{subDescription}</p>
          </div>
          <div className='col c2--800' />
        </div>
      </div>
      <div className='tc about__anything df jcc aic pb7'>
        <div className='container--700 x about__anything-flakes pr mxa' ref={ref}>
          <div className={cx('x pa  z2 top about__anything-specs left m1', inView && 'animate')}>
            <img className='mxa about__anything-specs-img' src='/images/sprinkle_1.png' /><br />
            <img className='mxa about__anything-specs-img' src='/images/sprinkle_2.png' /><br />
            <img className='mxa about__anything-specs-img' src='/images/sprinkle_3.png' /><br />
            <img className='mxa about__anything-specs-img' src='/images/sprinkle_4.png' /><br />
          </div>
          <Image className='x z1 pr about__anything-food' imageId={image.imageId} alt={title} />
          <div className='pill--big dib p1 pl3 pr3 bg--blue s24 s48--1000 color--pink'>
            <span className='p1'>{betterFoodCTA}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutAnything